import axios from 'axios'
import config from '@/config'

export default {
  async categoria ({ commit }) {
    const response = await axios.post(`${config.apiURI}service/departamento/getlist`).then(response => {
      return Promise.resolve(response.data)
    })

    commit('setCategoria', response)
  }
}
