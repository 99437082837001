/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

/* BlockUI */
import BlockUI from '@/components/custom/blockui/BlockUI'
export default {
  install: (app) => {
    library.add(fas, fab, far)

    app.component('font-awesome-icon', FontAwesomeIcon)

    app.component('block-ui', BlockUI)
  }
}
