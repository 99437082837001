<template>
  <HeaderComponentVue :visibleSearch="false"/>
  <router-view/>
  <FooterComponentVue :visibleSubscribe="false"/>
</template>

<script>
import HeaderComponentVue from '@/components/partials/HeaderComponent.vue'
import FooterComponentVue from '../partials/FooterComponent.vue'
export default {
  components: {
    HeaderComponentVue,
    FooterComponentVue
  },
  async created () {
    this.$store.dispatch('produto/categoria')
  }
}
</script>
