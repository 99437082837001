import DefaultLayout from '@/components/layout/DefaultLayout'

export default [
  {
    path: '/cart',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/page/cart/CartShopping.vue')
      },
      {
        path: 'finish',
        component: () => import('@/page/cart/CartFinishShopping.vue')
      }
    ]
  }
]
