export default {
  parametros (state, getters) {
    return state.parametros
  },
  meta (state, getters) {
    return state.parametros[getters]
  },
  consultores (state, getters) {
    return state.consultores[getters]
  }
}
