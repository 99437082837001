<template>
  <div class="mb-5">
    <div class="row m-0">
      <template v-for="carousel in carousels" :key="carousel.codcarousel">
        <div class="col p-0" :class="codposicao !== '1' ? 'me-2 ms-2' : ''">

          <div
          :id="`carousel-${carousel.codcarousel}`"
          class="carousel slide"
          :class="`carousel-${carousel.codposicao}`"
          data-bs-ride="carousel"
          data-bs-interval="500000">
            <div class="carousel-inner">
              <CarouselSlideComponentVue :codcarousel="carousel.codcarousel"/>
            </div>
            <template v-if="carousel.countimage !== '1'">
              <button class="carousel-control-prev" type="button" :data-bs-target="`#carousel-${carousel.codcarousel}`" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" :data-bs-target="`#carousel-${carousel.codcarousel}`" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axiosService from '@/services/axios.service'
import CarouselSlideComponentVue from './CarouselSlideComponent.vue'
export default {
  components: {
    CarouselSlideComponentVue
  },
  props: {
    codposicao: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      carousels: []
    }
  },
  async created () {
    const carousel = await axiosService.post('service/carousel/getlist', {
      codposicao: this.codposicao
    }).then(response => {
      console.log()
      return Promise.resolve(response.data)
    })
    this.carousels = typeof carousel === 'string' ? [] : carousel
  }
}
</script>
