import { createStore, createLogger } from 'vuex'

import carrinho from './carrinho'
import cliente from './cliente'
import ecommerce from './ecommerce'
import produto from './produto'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  modules: {
    carrinho,
    cliente,
    ecommerce,
    produto
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
export default store
