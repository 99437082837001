export default {
  setCheckout (state, payload) {
    state.checkout = payload
  },
  setItem (state, payload) {
    state.item = payload
  },
  addItem (state, payload) {
    state.item.push(payload)
  },
  editItem (state, payload) {
    state.item.filter(obj => obj.rowid === payload.rowid)[0].qcom = payload.qcom
  },
  remItem (state, payload) {
    state.item.splice(state.item.findIndex(obj => obj.rowid === payload.rowid), 1)
  },
  setConsultor (state, payload) {
    state.checkout.codvendedor = payload
  },
  setCliente (state, payload) {
    state.checkout.codclient = payload
  }
}
