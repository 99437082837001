<template>
  <nav class="navbar navbar-expand-lg navbar-primary" aria-label="Eleventh navbar example">
    <div class="container">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbar-primary">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="btn departamento" href="#" @mouseover="onDepto" @mouseleave="offDepto">
              Departamentos
            </a>

            <ul class="depto hide" ref="depto" @mouseover="onDepto" @mouseleave="offDepto">
              <template v-for="depto in list.filter(obj => obj.codparent === '0')" :key="depto.codcategoria">
                <li @mouseover="onSecao(`depto${depto.codcategoria}`)">
                  <router-link class="nav-link" :to="`/category/depto/${depto.codcategoria}/${uriSeo(depto.nome)}.html`">{{ depto.nome }}</router-link>
                  <ul class="secao hide" :ref="`depto${depto.codcategoria}`">
                    <li v-for="secao in list.filter(obj => obj.codparent === depto.codcategoria)" :key="secao.codcategoria" class="nav-item">
                      <router-link class="nav-link" :to="`/category/secao/${secao.codcategoria}/${uriSeo(secao.nome)}.html`">{{ secao.nome }}</router-link>
                    </li>
                  </ul>
                </li>
              </template>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" aria-current="page" href="#">Serviços</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style>
ul, li {
  list-style: none;
}
.navbar {
  padding: 0 !important;
}
.depto,
.secao {
  border: none;
  background: #fff;
  position: absolute;
  width: 250px;
  height: 450px;
  padding: 15px 5px;
}
.depto li,
.secao li {
  border-bottom: solid 1px #eee;
}
.depto li a,
.secao li a {
  padding: 5px 10px;
  font-weight: 600;
  font-size: 14px;
}
.depto.hide,
.secao.hide {
  display: none;
}
.depto {
  top: 45px;
  z-index: 1000;
  box-shadow: #888 0px 0px 5px;
}
.secao {
  top: 0;
  left: 250px;
  z-index: 1001;
  box-shadow: #888 0px 0px 5px;
}
#navbar-primary > ul > li > a {
  padding: 12px 20px;
}
.btn.departamento {
  border-radius: 0;
  color: #fff;
  width: 250px;
}
</style>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({ list: state => state.produto.categoria })
  },
  methods: {
    onDepto () {
      if (this.$refs.depto.classList.contains('hide')) {
        this.$refs.depto.classList.remove('hide')
      }
    },
    offDepto () {
      if (!this.$refs.depto.classList.contains('hide')) {
        this.$refs.depto.classList.add('hide')
      }
    },
    onSecao (id) {
      this.$el.querySelectorAll('.secao').forEach(el => {
        if (!el.classList.contains('hide')) {
          el.classList.add('hide')
        }
      })
      if (this.$refs[id][0].classList.contains('hide')) {
        this.$refs[id][0].classList.remove('hide')
      }
    }
  }
}
</script>
