import BlankLayout from '@/components/layout/BlankLayout'

export default [
  {
    path: '/account',
    component: BlankLayout,
    children: [
      {
        path: 'signin',
        component: () => import('@/page/account/AccountSignIn.vue')
      },
      {
        path: 'signup',
        component: () => import('@/page/account/AccountSignUp.vue')
      },
      {
        path: 'recovery',
        component: () => import('@/page/account/AccountRecovery.vue')
      },
      {
        path: 'recovery/:token',
        component: () => import('@/page/account/AccountRecoveryPass.vue')
      },
      {
        path: 'myaccount',
        component: () => import('@/page/account/MyAccount.vue')
      },
      {
        path: 'myorder',
        component: () => import('@/page/account/MyOrder.vue')
      },
      {
        path: 'myinvoices',
        component: () => import('@/page/account/MyInvoices.vue')
      }
    ]
  }
]
