<template>
  <nav class="py-1 bg-secundary border-bottom">
    <div class="container d-flex flex-wrap">
      <ul class="nav me-auto">
        <li class="nav-item"><router-link to="/" class="nav-link text-white px-2 active" aria-current="page">Início</router-link></li>
      </ul>
      <ul class="nav">
        <template v-if="!cliente.status.loggedIn">
          <li class="nav-item"><router-link to="/account/signin" class="nav-link text-white px-2">Entrar</router-link></li>
          <li class="nav-item"><router-link to="/account/signup" class="nav-link text-white px-2">Cadastrar-se</router-link></li>
        </template>
        <template v-else>
          <li class="nav-item"><router-link to="/account/myaccount" class="nav-link text-white px-2">{{ cliente.user.name }}</router-link></li>
          <li class="nav-item"><router-link to="/account/myaccount" class="nav-link text-white px-2">Minha Conta</router-link></li>
          <li class="nav-item"><router-link to="/account/myorder" class="nav-link text-white px-2">Meus Pedidos</router-link></li>
          <li class="nav-item"><a href="#" class="nav-link text-white px-2" @click="exitAccount">Sair</a></li>
        </template>
      </ul>
    </div>
  </nav>
  <header class="py-3 border-bottom bg-white">
    <div class="container d-flex flex-wrap align-items-center justify-content-center">
      <router-link to="/" class="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-dark text-decoration-none">
        <img class="logo" src="@/assets/logo.png">
      </router-link>
      <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" v-if="visibleSearch" role="search"  @submit.prevent="submit" >
        <b-input-group>
        <input type="search" name="q" class="form-control" placeholder="Pesquisar por ..." aria-label="search" v-model="arg">
          <b-input-group-append>
            <b-button type="submit" variant="primary">Buscar</b-button>
          </b-input-group-append>
        </b-input-group>
      </form>
      <div class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3 ms-lg-3"  v-if="visibleSearch">
        <router-link to="/cart" class="cart">
          <b-badge pill variant="primary" class="px-2 bs-4">{{ item.length }}</b-badge>
          <font-awesome-icon icon="fa-solid fa-cart-shopping" />
        </router-link>
      </div>
    </div>
  </header>
</template>

<style>
.cart {
  position: relative;
}
.cart svg {
  font-size: 34px;
  color: #052C65;
}
.cart .badge {
  position: absolute;
  bottom: -5px;
  right: -5px;
}
</style>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    visibleSearch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      item: state => state.carrinho.item,
      cliente: state => state.cliente
    })
  },
  data () {
    return {
      arg: ''
    }
  },
  methods: {
    submit () {
      this.$router.push(`/search?q=${this.arg}`)
      this.arg = ''
    }
  }
}
</script>
