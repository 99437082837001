<template>
  <b-breadcrumb class="mt-4 mb-3" v-if="list.length > 0">
    <template v-for="(el, ind) in list" :key="ind">
      <template v-if="(ind + 1) < list.length">
        <b-breadcrumb-item :href="el.to"> {{ el.label }} </b-breadcrumb-item>
      </template>
      <template v-else>
        <b-breadcrumb-item active> {{ el.label }} </b-breadcrumb-item>
      </template>
    </template>
    <template v-slot:prepend v-if="searching"><span class="mx-2"> Você pesquisou por: </span></template>
    <template v-slot:prepend v-else><span class="mx-2"> Você está em: </span></template>
  </b-breadcrumb>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
  data () {
    return {
      list: [],
      deptos: [],
      searching: false
    }
  },
  watch: {
    '$route.params.codparent' (n, o) {
      if (n !== o && n !== 'undefined') {
        this.getlist()
      }
    },
    '$route.params.codcategoria' (n, o) {
      if (n !== o && n !== 'undefined') {
        this.getlist()
      }
    },
    '$route.params.codprod' (n, o) {
      if (n !== o && n !== 'undefined') {
        this.getlist()
      }
    },
    '$route.query.q' (n, o) {
      this.search(n)
    }
  },
  methods: {
    async getlist () {
      this.list = []
      if (typeof this.$route.params.codparent !== 'undefined' && this.$route.params.codparent !== '') {
        const depto = this.deptos.filter(obj => obj.codcategoria === this.$route.params.codparent)[0]
        this.list[0] = {
          to: `/category/depto/${depto.codcategoria}/${this.uriSeo(depto.nome)}.html`,
          label: depto.nome
        }
      } else if (typeof this.$route.params.codcategoria !== 'undefined' && this.$route.params.codcategoria !== '') {
        const secao = this.deptos.filter(obj => obj.codcategoria === this.$route.params.codcategoria)[0]
        const depto = this.deptos.filter(obj => obj.codcategoria === secao.codparent)[0]
        this.list[0] = {
          to: `/category/depto/${depto.codcategoria}/${this.uriSeo(depto.nome)}.html`,
          label: depto.nome
        }
        this.list[1] = {
          to: `/category/secao/${secao.codcategoria}/${this.uriSeo(secao.nome)}.html`,
          label: secao.nome
        }
      } else if (typeof this.$route.params.codprod !== 'undefined' && this.$route.params.codprod !== '') {
        const produ = await axios.post(`${config.apiURI}service/produto/get`, {
          codprod: this.$route.params.codprod,
          codfilial: this.$route.params.codfilial,
          codregiao: this.getRegiao()
        }).then(response => {
          return Promise.resolve(response.data)
        })
        const secao = this.deptos.filter(obj => obj.codcategoria === produ.codcategoria)[0]
        const depto = this.deptos.filter(obj => obj.codcategoria === secao.codparent)[0]
        this.list[0] = {
          to: `/category/depto/${depto.codcategoria}/${this.uriSeo(depto.nome)}.html`,
          label: depto.nome
        }
        this.list[1] = {
          to: `/category/secao/${secao.codcategoria}/${this.uriSeo(secao.nome)}.html`,
          label: secao.nome
        }
        this.list[2] = {
          to: '',
          label: produ.descricao
        }
      }
    },
    search () {
      this.list = []
      if (typeof this.$route.query.q !== 'undefined') {
        this.list[0] = {
          to: '',
          label: this.$route.query.q
        }
        this.searching = true
      } else {
        this.searching = false
      }
    }
  },
  async mounted () {
    this.deptos = await axios.post(`${config.apiURI}service/departamento/getlist`).then(response => {
      return Promise.resolve(response.data)
    })

    this.getlist()
    this.search()
  }
}
</script>
