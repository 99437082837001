import axios from 'axios'
import authHeader from '@/services/auth-header'
import config from '@/config'

import swal from 'sweetalert'

class AxiosService {
  get (uri, header = {}) {
    try {
      return this.then(axios.get(config.apiURI + uri, this.headers(header)))
    } catch (error) {
      swal('Error', error.message, 'error')
    }
  }

  post (uri, params = {}, header = {}) {
    try {
      return this.then(axios.post(config.apiURI + uri, params, this.headers(header)))
    } catch (error) {
      swal('Error', error.message, 'error')
    }
  }

  headers (head) {
    if ('headers' in head) {
      head.headers.concat(authHeader())
    } else {
      head.headers = authHeader()
    }
    return head
  }

  then (service) {
    return service.then(
      response => {
        return response
      },
      error => {
        if (typeof error.response !== 'undefined') {
          switch (error.response.status) {
            case 401:
              swal('Sua sessão expirou', 'É preciso realizar um novo login', 'warning')
              localStorage.removeItem('user')
              break
            default:
              swal('Request', error.message, 'error')
              break
          }
        }

        return typeof error.response !== 'undefined' ? error.response : error
      }
    )
  }
}
export default new AxiosService()
