import DefaultLayout from '@/components/layout/DefaultLayout'

export default [
  {
    path: '/product',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/page/product/ProductList.vue')
      },
      {
        path: 'detail/:codprod/:codfilial/:descricao',
        component: () => import('@/page/product/ProductDetail.vue')
      }
    ]
  },
  {
    path: '/search',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/page/product/ProductList.vue')
      }
    ]
  },
  {
    path: '/category',
    component: DefaultLayout,
    children: [
      {
        path: 'depto/:codparent/:descricao',
        component: () => import('@/page/product/ProductList.vue')
      },
      {
        path: 'secao/:codcategoria/:descricao',
        component: () => import('@/page/product/ProductList.vue')
      }
    ]
  }
]
