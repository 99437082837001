import axios from 'axios'
import config from '@/config'

export default {
  async parametros ({ commit }) {
    const response = await axios.post(`${config.apiURI}service/ecommerce/get`, {
      codecommerce: config.codecommerce
    }).then(response => {
      return Promise.resolve(response.data)
    })

    commit('setParametros', response)
    commit('setTitle', response.title)
    commit('setKeywords', response.keywords)
    commit('setDescription', response.description)
  },
  meta ({ commit }, payload) {
    switch (payload.prop) {
      case 'title':
        commit('setTitle', payload.content)
        break
      case 'keywords':
        commit('setKeywords', payload.content)
        break
      case 'description':
        commit('setDescription', payload.content)
        break
      default:
        break
    }
  },
  async consultores ({ commit }) {
    const response = await axios.get(`${config.apiURI}service/vendedor/getlist`).then(response => {
      return Promise.resolve(response.data)
    })

    commit('setConsultores', response)
  }
}
