import config from '@/config'

export default {
  methods: {
    currentFormat (value, minimumFractionDigits = 2, lang = 'pt-BR', style = null, currency = null) {
      const options = {}
      try {
        value = parseFloat(value)
      } catch (error) {
        console.log(error)
      }
      if (typeof value !== 'number') {
        console.log('no number', value)
        return value
      }
      if (currency !== null) {
        options.currency = currency
      }
      if (style !== null) {
        options.style = style
      }
      if (currency !== null) {
        options.currency = currency
      }
      if (minimumFractionDigits !== null) {
        options.minimumFractionDigits = minimumFractionDigits
        options.maximumFractionDigits = minimumFractionDigits
      }
      const formatter = new Intl.NumberFormat(lang, options)
      return formatter.format(value)
    },

    crypto (value) {
      return window.btoa(value)
    },

    decrypto (value) {
      return window.atob(value)
    },

    uriFirstImage (id) {
      return `${config.apiURI}image/file/first/${id}`
    },

    uriOpenImage (id) {
      return `${config.apiURI}image/file/open/${id}`
    },

    uriSeo (text) {
      const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
      const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
      const p = new RegExp(a.split('').join('|'), 'g')
      return text.toString().toLowerCase().trim()
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
      // return string.toLowerCase().replaceAll(' ','-')
    },

    getRegiao () {
      return this.$store.state.cliente.user === null ? 1 : this.$store.state.cliente.user.regiao
    },

    getFiliais () {
      return '1,2'
    },

    getCobranca () {
      return 'D'
    },

    getPlanoPag () {
      return 1
    },

    getUfDest () {
      return 'RO'
    },

    scrollMeContent () {
      window.scrollTo(0, 200)
    },

    exitAccount () {
      this.$store.dispatch('cliente/logout')
    }
  }
}
