import { createRouter, createWebHistory } from 'vue-router'
// import AuthService from 'mk-auth/src/services/auth.service'

import DefaultLayout from '@/components/layout/DefaultLayout'
import BlankLayout from '@/components/layout/BlankLayout'

import accountRouter from './account'
import cartRouter from './cart'
import productRouter from './product'
import axios from '@/services/axios.service'

import store from '@/store'

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: () => import('@/page/main/MainPage.vue'),
        meta: {
          carousel: true
        }
      }
    ]
  },
  {
    path: '/teste',
    component: BlankLayout
  }
]
  .concat(accountRouter)
  .concat(cartRouter)
  .concat(productRouter)

routes.push({
  path: '/:pathMatch(.*)*',
  component: () => import('@/page/NotFound.vue')
})

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory('/b2b/'),
  routes // short for `routes: routes`
})

router.beforeEach(async (to, from, next) => {
  const privatePages = [
    '/account/myaccount',
    '/account/myorder',
    '/account/myinvoices',
    '/cart',
    '/cart/finish'
  ]
  const authRequired = privatePages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (loggedIn) {
    const json = JSON.parse(loggedIn)
    const statsUser = await axios.post('service/sessao/status', { accessToken: json.accessToken }).then(response => Promise.resolve(response.data))
    if (!statsUser) {
      store.dispatch('cliente/logout')
      console.log()
    }
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/account/signin')
  } else {
    next()
  }
})

export default router
