<template>
  <suspense>
    <router-view/>
  </suspense>
</template>

<script>
import { mapState, useStore } from 'vuex'
export default {
  computed: {
    ...mapState({
      parametros: state => state.ecommerce.parametros,
      meta: state => state.ecommerce.meta
    })
  },
  watch: {
    'meta.title' (n, o) {
      document.title = n
    },
    'meta.keywords' (n, o) {
      document.keywords = n
    },
    'meta.description' (n, o) {
      document.description = n
    }
  },
  setup () {
    const store = useStore()
    store.dispatch('ecommerce/parametros')
    store.dispatch('ecommerce/consultores')
    store.dispatch('produto/categoria')
    store.dispatch('carrinho/get')
  }
}
</script>
