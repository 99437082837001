import axios from 'axios'
import config from '@/config'

export default {
  login ({ commit }, user) {
    return axios.post(`${config.apiURI}service/sessao/autenticar`, user).then(
      response => {
        if (response.data.sucess) {
          commit('loginSuccess', response.data)
        }
        return Promise.resolve(response)
      },
      error => {
        commit('loginFailure')
        return Promise.reject(error)
      }
    )
  },
  logout ({ commit }) {
    localStorage.removeItem('user')
    commit('logout')
  }
}
