import axios from '@/services/axios.service'

export default {
  async get ({ commit }) {
    const checkout = localStorage.getItem('checkout')

    const response = await axios.post('service/carrinho/get', {
      codcheckout: checkout
    }).then(response => {
      return Promise.resolve(response.data)
    })

    if (!checkout) {
      localStorage.setItem('checkout', response.checkout.codcheckout)
    }
    localStorage.setItem('checkout', response.checkout.codcheckout)
    commit('setCheckout', response.checkout)
    commit('setItem', response.item)
  },

  async add ({ commit }, payload) {
    const response = await axios.post('service/carrinho/add', {
      codcheckout: payload.codcheckout,
      codprod: payload.codprod,
      codfilial: payload.codfilial,
      codregiao: payload.codregiao,
      qcom: payload.qcom
    }).then(response => {
      return Promise.resolve(response.data)
    })

    payload.rowid = response.codigo
    payload.vuncom = response.vuncom
    payload.vuncomorig = response.vuncomorig

    if (response.sucess) {
      commit('addItem', payload)
    }
    return response
  },

  async edit ({ commit }, payload) {
    const response = await axios.post('service/carrinho/edit', {
      codcheckout: payload.codcheckout,
      rowid: payload.rowid,
      qcom: payload.qcom
    }).then(response => {
      return Promise.resolve(response.data)
    })

    if (response.sucess) {
      commit('editItem', payload)
    }
    return response
  },

  async rem ({ commit }, payload) {
    const response = await axios.post('service/carrinho/rem', {
      codcheckout: payload.codcheckout,
      rowid: payload.rowid
    }).then(response => {
      return Promise.resolve(response.data)
    })

    if (response.sucess) {
      commit('remItem', payload)
    }
    return response
  },

  async setvendedor ({ commit }, payload) {
    const response = await axios.post('service/carrinho/setconsultor', {
      codcheckout: payload.codcheckout,
      codvendedor: payload.codvendedor
    }).then(response => {
      return Promise.resolve(response.data)
    })

    if (response.sucess) {
      commit('setConsultor', payload.codvendedor)
    }
    return response
  }
}
