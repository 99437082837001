export default {
  setParametros (state, payload) {
    state.parametros = payload
  },
  setTitle (state, payload) {
    state.meta.title = payload
  },
  setKeywords (state, payload) {
    state.meta.keywords = payload
  },
  setDescription (state, payload) {
    state.meta.description = payload
  },
  setConsultores (state, payload) {
    state.consultores = payload
  }
}
