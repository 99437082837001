<template>
  <template v-for="(image, index) in images" :key="image.codarquivo">
    <div class="carousel-item" :class="{ active: index === 0 }">
      <a v-if="image.link !== ''" :href="image.link">
        <img :src="uriOpenImage(image.codarquivo)" class="d-block w-100" alt="...">
      </a>
      <template v-else>
        <img :src="uriOpenImage(image.codarquivo)" class="d-block w-100" alt="...">
      </template>
    </div>
  </template>
</template>

<script>
import axiosService from '@/services/axios.service'
export default {
  props: {
    codcarousel: [String, Number]
  },
  data () {
    return {
      images: []
    }
  },
  async created () {
    this.images = await axiosService.post('service/carousel/get', {
      codcarousel: this.codcarousel
    }).then(response => {
      return Promise.resolve(response.data)
    })
  }
}
</script>
