<template>
  <HeaderComponentVue/>
  <NavBarComponentVue/>
  <CarouselComponentVue v-if="$route.meta.carousel"/>
  <b-container>
    <BreadCrumbComponentVue/>
  </b-container>
  <Suspense>
    <router-view/>
  </Suspense>
  <FooterComponentVue/>
</template>

<style>
* {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}
body {
  background-color: #F2F3F4;
}
header .logo {
  width: 200px;
  height: 80px;
}
footer {
  background-color: #0A58CA !important;
  color: #E2EDFE;
}
footer .nav-link {
  font-size: 0.75rem;
}
footer .nav-link.h4 {
  font-size: 0.875rem;
  font-weight: 700;
}
footer .b-t {
  border-top: #0946A2 solid 1px;
}
footer .text-muted {
  color: #BBD6FD !important;
}
.bg-secundary {
  background-color: #052C65 !important;
  color: #E2EDFE;
}
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}
.bs-4 {
  box-shadow: #777 0px 0px 10px;
}
.navbar-primary {
  background-color: #0A58CA !important;
}
.navbar-primary .departamento {
  background-color: #052C65 !important;
}
.card-body {
  padding: 0.5rem;
}
.card-title {
  font-size: 14px;
  font-weight: 700;
}
.card-text {
  font-size: 12px;
}
.product:hover {
  box-shadow: #777 0px 0px 4px;
}
.product .card-title,
.product .card-text {
  text-decoration: none;
  color: #555;
}
.product .card-title {
  display: block;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product .card-text {
  display: block;
  height: 20px;
}
.card-price span.value-by,
.card-price span.value-from {
  display: block;
}
.card-price span.value-by {
  font-size: 16px;
  font-weight: bold;
  color: #0d6efd;
}
.card-price span.value-from {
  text-decoration: line-through;
  font-size: 14px;
}
.view-price span.value-by,
.view-price span.value-from {
  display: block;
}
.view-price span.value-by {
  font-size: 26px;
  font-weight: bold;
  color: #0d6efd;
}
.view-price span.value-from {
  text-decoration: line-through;
  font-size: 14px;
}
.card.product img {
  object-fit: scale-down;
  width: 100%;
  height: 190px;
}
.placeholder-card img {
  height: 360px;
}
.nav-form {
  align-items: center;
}
.nav-form label {
  margin-right: 5px;
}
.nav-form select {
  margin-right: 10px;
  width: 180px;
}
.carousel-1 .carousel-item img {
  object-fit: none;
  height: 480px;
}
@media (max-width: 720px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .navbar-nav li.nav-form {
    margin-bottom: 3px;
  }
  .navbar-nav li label {
    width: 50%;
  }
}

.account .title,
.account .title svg {
  font-size: 18px;
  font-weight: 700;
  margin-right: 5px;
}
.account .title a {
  text-decoration: none;

}
.account .title svg,
.account .title a {
  color: #0A58CA;
}
.py-200 {
  padding: 200px 0;
}
</style>

<script>

import HeaderComponentVue from '@/components/partials/HeaderComponent.vue'
import NavBarComponentVue from '@/components/partials/NavBarComponent.vue'
import FooterComponentVue from '@/components/partials/FooterComponent.vue'
import BreadCrumbComponentVue from '@/components/partials/BreadCrumbComponent.vue'
import CarouselComponentVue from '../partials/CarouselComponent.vue'

export default {
  components: {
    HeaderComponentVue,
    NavBarComponentVue,
    FooterComponentVue,
    BreadCrumbComponentVue,
    CarouselComponentVue
  }
}
</script>
