<template>
  <footer>
    <div class="bg-secundary py-5" v-if="visibleSubscribe">
      <div class="container">
        <div class="row">
          <div class="col-md-10 mb-3">
            <template v-if="registred">
              <div class="display-6 font-weight-bolder mb-4">Inscrição realizado com sucesso!</div>
            </template>
            <template v-else>
              <form>
                <h5>Assine a nossa newsletter</h5>
                <p>Receba nossas ofertas</p>
                <div class="d-flex flex-column flex-sm-row w-100 gap-2">
                  <label for="name" class="visually-hidden">Seu nome</label>
                  <input id="name" type="text" class="form-control" placeholder="Seu nome" v-model="newsletter.nome">
                  <label for="email" class="visually-hidden">Seu e-mail</label>
                  <input id="email" type="text" class="form-control" placeholder="Seu e-mail" v-model="newsletter.email">
                  <button class="btn btn-primary" type="button" @click="signNewsLetter" v-bind="{ disabled: loading }"> <span v-show="loading" class="spinner-border spinner-border-sm"/> Se inscrever</button>
                </div>
              </form>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-5">
      <div class="row">
        <div class="col mb-1" v-for="depto in list.filter(obj => obj.codparent === '0')" :key="depto.codcategoria">
          <router-link class="nav-link h4 mb-3" :to="`/category/depto/${depto.codcategoria}/${uriSeo(depto.nome)}.html`">{{ depto.nome }}</router-link>
          <ul class="nav flex-column">
            <li class="nav-item mb-2" v-for="secao in list.filter(obj => obj.codparent === depto.codcategoria)" :key="secao.codcategoria">
              <router-link class="nav-link p-0 text-muted" :to="`/category/secao/${secao.codcategoria}/${uriSeo(secao.nome)}.html`" @click="scrollMeContent">{{ secao.nome }}</router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="d-flex flex-column flex-sm-row justify-content-between py-4 b-t">
        <p>© 2022 Gurgel Distribuidora, Todos os direitos reservados.</p>
        <ul class="list-unstyled d-flex">
          <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#twitter"></use></svg></a></li>
          <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#instagram"></use></svg></a></li>
          <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#facebook"></use></svg></a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import axiosService from '@/services/axios.service'
import { mapState } from 'vuex'
export default {
  props: {
    visibleSubscribe: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      newsletter: {
        nome: '',
        email: ''
      },
      loading: false,
      registred: false
    }
  },
  computed: {
    ...mapState({ list: state => state.produto.categoria })
  },
  methods: {
    async signNewsLetter () {
      this.loading = true
      const letter = await axiosService.post('service/newsletter/add', this.newsletter).then(
        response => Promise.resolve(response.data)
      )
      this.loading = false

      if (letter.sucess) {
        this.registred = true
      }

      console.log(letter)
    }
  }
}
</script>
