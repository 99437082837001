import { createApp } from 'vue'
import App from './App.vue'

import BootstrapVue3 from 'bootstrap-vue-3'
import VueLazyLoad from 'vue3-lazyload'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import globalComponent from './plugins/global-component'
import globalMixin from './plugins/global-mixin'

import router from './router'
import store from './store'

import spinner from '@/assets/image-loading.svg'

createApp(App)
  .use(BootstrapVue3)
  .use(VueLazyLoad, {
    loading: spinner
  })
  .use(router)
  .use(store)
  .use(globalComponent)
  .mixin(globalMixin)
  .mount('#app')
